<template>
  <div class="main-detail">    
    <div class="main mat">
      
        <div class="outer-wrap">
            <div class="banner-box">
                <div class="container">
                    <div class="clear-box">
                        <span class="fl logo-top"><img src="./static/picture/logo.png" width="243" height="64"></span>
                        <div class="fr">
                            <div class="lag">
                                <span><a href="javascript:void(0);" class="ch">中文</a></span>
                                <span>|</span>
                                <span><a href="javascript:void(0);" class="en">English</a></span>
                            </div>
                            <div class="input-box">
                                <input @keyup.enter="searchBtnFn" v-model="searchVal" id="searchContent" name="searchContent" type="text" class="search-input" placeholder="请输入查找内容">
                                <span class="in-icon">
                                    <a href="javascript:void(0);" class="in-icon-a" @click="searchBtnFn"></a>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="nav-box">
                        <ul id="nav" style="position:relative;z-index: 1" >
                            <li><a href="/index" style="border-left:none" class="nav-text fl   "><span>返回首页</span></a></li>
                        </ul>
                    </div>
                </div>
                <div class="content-box">
                    <img src="./static/images/bg1.jpg" alt="" style="height:666px;width:1200px">
                    <div class="part-box1">
                        <div class="fl text-2021">
                            <div style="margin-top:90px;margin-bottom:20px;">
                                <img src="./static/picture/jj.png"  alt="简介" title="简介" />
                            </div>
                            <span>
                                <p v-html="categoryDescrip ? categoryDescrip : ''"  style="text-align: justify;"></p>
                            </span>
                            <div style="margin-top:25px;width:130px; height:42px; background:#1b7ed6;">
                                <a href="/ms/file/getimage/1093" target="_blank" style="display:block; text-align:center; font-size:14px; line-height:42px;color:#fff">议程下载</a>
                            </div>
                        </div>
                        <div class="fr" style="margin-top:120px;">
                            <img src="./static/picture/c3a19d1c3261472b9cddf23cbe190633.gif" style="width:362px;" alt="简介" title="简介" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="part-box2" style="margin-top:700px">
            <div class="fl">
                <span class="title1" style="padding-top:40px;"><img src="./static/picture/ltgd.png" width="668" height=""></span>
                <div class="po-box" id="picture_box3">
                    <div class="g-news" style="padding-top:20px">
                        <div class="pic-slide-box" id="pic-slide-box3">
                            <el-carousel :interval="5100" arrow="never" :indicator-position="'none'" ref="cardshow1">
                                <el-carousel-item v-for="(item,i) in dataOne" :key="i">
                                    <ul class="viewpoint_text" style="display:block">
                                        <li v-for="(sitem,j) in item" :key="j">
                                            <a
                                            class="g-news-text"
                                            :href="sitem.contentIsChain == '1' ? sitem.contentChain : '/detail?docId='+sitem.id"
                                            target="_blank"
                                            >{{sitem.contentTitle ? sitem.contentTitle : ''}}</a>
                                        </li>
                                    </ul>
                                </el-carousel-item>
                            </el-carousel>
                        </div>
                        <div class="fr">
                            <span>
                                <a @click="arrowClick1('left')" href="javascript:void(0)" class="l-btn fl" style="border:0px" id="switchleft3"><img src="./static/picture/arrow-left.png"></a>
                            </span>
                            <span><a @click="arrowClick1('right')" href="javascript:void(0)" class="r-btn fl" id="switchright3"><img src="./static/picture/arrow-right.png"></a></span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="fr">
                <span class="title2" style="padding-top:40px; display:block;"><img src="./static/picture/ltcg.png" width="478" height=""></span>
                <div class="cg-box">
                    <div class="cg-btn-1 cg-btn" :style="i == 0 ? 'margin-top:50px' : 'margin-top:0'" v-for="(sitem,i) in dataTwo" :key="i">
                        <span>{{sitem.contentTitle ? sitem.contentTitle : ''}}</span>
                        <a :href="sitem.contentIsChain == '1' ? sitem.contentChain : '/detail?docId='+sitem.id" class="cg-btn-detail detail-1" target="_blank">查看详情</a>
                    </div>
                </div>
            </div>
        </div>

        <div class="po-box" id="picture_box2">

            <div class="part-box4" style="margin-top:30px">
                <span class="title4"><img src="./static/picture/mtbd.png" width="1200" height=""></span>
                <div class="pic-slide-box" id="pic-slide-box2">
                    <el-carousel :interval="5100" arrow="never" :indicator-position="'none'" ref="cardshow2">
                        <el-carousel-item v-for="(item,i) in dataThree" :key="i">
                            <div>
                                <ul style="display:block">
                                    <li 
                                        v-for="(sitem,j) in item" :key="j" 
                                        class="con-box-4 fl" 
                                    >
                                        <a 
                                            :href="sitem.contentIsChain == '1' ? sitem.contentChain : '/detail?docId='+sitem.id"
                                            target="_blank" 
                                            :title="sitem.contentTitle ? sitem.contentTitle : ''"
                                        >
                                            <div class="fl">
                                                <img :src="(sitem.imgSrc && sitem.imgSrc.length > 0) ? imgBase + sitem.imgSrc[0] : defaultImg1" style="width:100px;height:80px"/>
                                                
                                            </div>
                                            <div class="con-box-text-4 fl">
                                                <div class="cbox-4">
                                                    <a :href="sitem.contentIsChain == '1' ? sitem.contentChain : '/detail?docId='+sitem.id" target="_blank">
                                                        <p>{{sitem.contentTitle ? sitem.contentTitle : ''}}</p>
                                                    </a>
                                                </div>
                                                <div class="nact-selllist-btns-4">{{sitem.contentDescription ? sitem.contentDescription : ''}}</div>
                                            </div>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </el-carousel-item>
                    </el-carousel>
                </div>
                <div class="fr" style="margin-top:30px;">
                    <span><a @click="arrowClick2('left')" href="javascript:void(0)" class="l-btn fl" style="border:0px" id="switchleft2" ><img src="./static/picture/arrow-left.png"></a></span>
                    <span><a @click="arrowClick2('right')" href="javascript:void(0)" class="r-btn fl" id="switchright2" ><img src="./static/picture/arrow-right.png"></a></span>
                </div>

            </div>
        </div>

        <div class="part-box5" >
            <span class="title5"><img src="./static/picture/tpjj.png" width="1200" height=""></span>
        </div>
    
        <div class="cebg">
            <div class="rebox">
                <div class="m_fullImgsWrap">
                    <div class="m_fullImgs bd" id="Marquee_x" >
                        <ul class="" style="width: 1000%;">
                            <li style="margin-right: 10px;">
                                <div style="margin-right: 10px;" class="m_fullImg" v-for="(item,i) in dataFour" :key="i">
                                    <a :href="item.contentIsChain == '1' ? item.contentChain : '/detail?docId='+item.id" target="_blank" >
                                        <img :src="(item.imgSrc && item.imgSrc.length > 0) ? imgBase + item.imgSrc[0] : defaultImg1" style="width:345px;height:250px">
                                    </a>
                                </div>
                            </li>
                            <li style="margin-right: 10px;">
                                <div style="margin-right: 10px;" class="m_fullImg" v-for="(item,i) in dataFour" :key="i">
                                    <a :href="item.contentIsChain == '1' ? item.contentChain : '/detail?docId='+item.id" target="_blank" >
                                        <img :src="(item.imgSrc && item.imgSrc.length > 0) ? imgBase + item.imgSrc[0] : defaultImg1" style="width:345px;height:250px">
                                    </a>
                                </div>
                            </li>
                            <!-- <li style="margin-right: 10px;">
                                <div style="margin-right: 10px;" class="m_fullImg">
                                    <a href="/common/fs/GetDocPictureUrl?docid=1794&width=0&height=0">
                                        <img src="./static/picture/e53cc45ed7ea4abfb15a0c5c7c577458.gif" style="width:345px;height:250px">
                                    </a>
                                </div>
                                <div style="margin-right: 10px;" class="m_fullImg">
                                    <a href="/common/fs/GetDocPictureUrl?docid=1779&width=0&height=0">
                                        <img src="./static/picture/9a8b371c35c5416ebd227e199c434645.gif" style="width:345px;height:250px">
                                    </a>
                                </div>
                            </li> -->
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="part-box6">
            <span class="title6"><img src="./static/picture/forum2019.png" width="1200" height=""></span>
        </div>
        <div class="fbg-box">
            <div class="conbox">
                <div class="content-box-6">
                    <div>
                        <ul>
                            <li class="fl">
                                <a class="con-box-6" href="/ms/file/getimage/681" target="_blank" >
                                    <img src="./static/picture/icon1.png" width="58" height="">
                                    <span class="con-box-text6">2019年可持续发展论坛议程</span>
                                </a>
                            </li>
                            <li class="fl" style="margin:0 60px;">
                                <a class="con-box-6" href="/ms/file/getimage/662" target="_blank" >
                                    <img src="./static/picture/icon2.png" width="58" height="">
                                    <span class="con-box-text6">可持续发展企业行动倡议 </span>
                                </a>
                            </li>
                            <li class="fl">
                                <a class="con-box-6" href="/ms/file/getimage/665" target="_blank" >
                                    <img src="./static/picture/icon3.png" width="58" height="">
                                    <span class="con-box-text6">大会倡议</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
    <Footer></Footer>
    <!-- 回到顶部 -->
    <el-backtop target=".main-detail" :bottom="100">
        <!-- <div
            style="{
                height: 100%;
                width: 100%;
                background-color: #f2f5f6;
                box-shadow: 0 0 6px rgba(0,0,0, .12);
                text-align: center;
                line-height: 40px;
                color: #1989fa;
            }"
        >
            UP
        </div> -->
        <i class="el-icon-caret-top"></i>
    </el-backtop>
  </div>
</template>

<script>
import $ from "jquery";
import api from "@/../static/api/api.js";

import Footer from "@/components/footer.vue";

import defaultImg1 from "@/assets/imgs/default.png";
import defaultImg2 from "@/assets/imgs/default2.png";
import defaultImg3 from "@/assets/imgs/default3.png";

export default {
  name: "communicate",
  data() {
    return {
      imgBase: this.$store.state.imgBase,
      defaultImg1: defaultImg1, //轮播图默认图片
      defaultImg2: defaultImg2, //研究项目默认图
      defaultImg3: defaultImg3, //交流传播默认图
      dialogVisible: false,     //弹框是否显示
      menuList: [],//栏目数据
      categoryDescrip: '',      //栏目简介
      dataOne: [],              //论坛动态
      dataTwo: [],              //论坛成果
      dataThree: [],            //媒体报道
      dataFour: [],             //图片集锦
      dataFive: [],             //图片集锦
      curtitle: '',
      searchVal: '',
    };
  },
  components: {
    Footer
  },
  mounted() {
    document.title = '交流传播 | 知识中心'
    this.fetchData();
    this.beforeJsCode();
    this.fetchDataOne();
    this.fetchDataTwo();
    this.fetchDataThree();
    this.fetchDataFour();
  },
  methods: {
    fetchData() {
        let _this = this;

        let menuId1 = '1470628929258229761';
        let onlyChild1 = true;
        let paltformId1 = '';
        api.getTopMenuList(menuId1, onlyChild1, paltformId1).then(res => {
            if (res.data) {
                _this.categoryDescrip = res.data.categoryDescrip.replace(/\n/g, "<br/>");;
                _this.menuList = res.data.childrenList; 
            }
        });
    },
    //论坛动态
    fetchDataOne(){
        let _this = this;
        let menuId = '1470671581349507073';
        let pageNum = 1;
        let pageSize = 50;
        api.getArticleList(menuId, pageNum, pageSize).then(res => {
            if (res.data) {
                let tmpData = res.data.list;
                _this.curtitle = res.data.title;
                _this.dataOne = [];
                let tmpArr = [];
                if(tmpData.length > 0){
                    for(let i=0;i<tmpData.length;i++){
                        if(i%5 == 4 || i == (tmpData.length-1)){
                            tmpArr.push(tmpData[i])
                            _this.dataOne.push(tmpArr);
                            tmpArr = [];
                        }else{
                            tmpArr.push(tmpData[i])
                        }
                    }
                }
            }
        });
    },
    //论坛成果
    fetchDataTwo(){
        let _this = this;
        let menuId = '1470671509647880194';
        let pageNum = 1;
        let pageSize = 20;
        api.getArticleList(menuId, pageNum, pageSize).then(res => {
            if (res.data) {
                _this.dataTwo = res.data.list;
                _this.curtitle = res.data.title;
            }
        });
    },
    //媒体报道
    fetchDataThree(){
        let _this = this;
        let menuId = '1470671343998038018';
        let pageNum = 1;
        let pageSize = 50;
        api.getArticleList(menuId, pageNum, pageSize).then(res => {
            if (res.data) {
                let tmpData = res.data.list;
                _this.curtitle = res.data.title;
                _this.dataThree = [];
                let tmpArr = [];
                if(tmpData.length > 0){
                    for(let i=0;i<tmpData.length;i++){
                        if(i%5 == 4 || i == (tmpData.length-1)){
                            tmpArr.push(tmpData[i])
                            _this.dataThree.push(tmpArr);
                            tmpArr = [];
                        }else{
                            tmpArr.push(tmpData[i])
                        }
                    }
                }
            }
        });
    },
    //图片集锦
    fetchDataFour(){
        let _this = this;
        let menuId = '1470671762337918978';
        let pageNum = 1;
        let pageSize = 50;
        api.getArticleList(menuId, pageNum, pageSize).then(res => {
            if (res.data) {
                _this.dataFour = res.data.list;
                _this.curtitle = res.data.title;
            }
        });
    },
    //获取URL参数
    getQueryString(key) {
        var reg = new RegExp("(^|&)" + key + "=([^&]*)(&|$)");
        var result = window.location.search.substr(1).match(reg);
        return result ? decodeURIComponent(result[2]) : null;
    },
    //点击观点的轮播图左右按钮
    arrowClick1(val){
        if(val == 'right'){
            this.$refs.cardshow1.next();
        }else{
            this.$refs.cardshow1.prev();
        }
    },
    //点击媒体报道的轮播图左右按钮
    arrowClick2(val){
        if(val == 'right'){
            this.$refs.cardshow2.next();
        }else{
            this.$refs.cardshow2.prev();
        }
    },
    //点击搜索按钮
    searchBtnFn(){
        if(!this.searchVal){
            this.$message('请输入搜索内容！');
        }else{
            window.location.href = '/searchpage?keyword=' + this.searchVal;
        }
    },
    // 图片集锦轮播图
    beforeJsCode(){
        $.fn.jcMarquee = function(options) {
            var defaults = {
                'marquee':'x',
                'margin_bottom':'0',
                'margin_right':'0',
                'speed':'10'
            };
            var options = $.extend(defaults,options);
            return this.each(function() {
                var $marquee = $(this),
                    $marquee_scroll = $marquee.children('ul');
                $marquee_scroll.append("<li class='clone'>"+"</li>");
                $marquee_scroll.find('li').eq(0).children().clone().appendTo('.clone');
                var $marquee_left = $marquee_scroll.find('li');
                if (options.marquee == 'x') {
                    var x = 0;
                    $marquee_scroll.css('width','1000%');
                    $marquee_left.find('div').css({'margin-right':options.margin_right});
                    $marquee_left.css({'margin-right':options.margin_right});
                    function Marquee_x(){ 
                        $marquee.scrollLeft(++x);
                        var _margin = parseInt($marquee_left.find('div').css('margin-right'));
                        if(x==$marquee_left.width()+_margin) { x = 0 };
                    };
                    var MyMar=setInterval(Marquee_x,options.speed); 
                    $marquee.hover(function(){
                        clearInterval(MyMar);
                    },function(){
                        MyMar=setInterval(Marquee_x,options.speed);
                    });
                }
                if (options.marquee == 'y') {
                    var y = 0;
                    $marquee_scroll.css('height','1000%');
                    $marquee_left.find('div').css('margin-bottom',options.margin_bottom);
                    $marquee_left.css('margin-bottom',options.margin_bottom);
                    function Marquee_y(){ 
                        $marquee.scrollTop(++y);
                        var _margin = parseInt($marquee_left.find('div').css('margin-bottom'));
                        if(y==$marquee_left.height()+_margin) { y = 0 };
                    };
                    var MyMar=setInterval(Marquee_y,options.speed); 
                    $marquee.hover(function(){
                        clearInterval(MyMar);
                    },function(){
                        MyMar=setInterval(Marquee_y,options.speed);
                    });
                };
            });
        };
        var w =$(document.body).width();
        $('#Marquee_x').width(w+"px");
        //alert($('#Marquee_x').width());
        $('#Marquee_x').jcMarquee({ 'marquee':'x','margin_right':'10px','speed':20 });
        // DIVCSS5提示：10px代表间距，第二个20代表滚动速度
    }
  }
};
</script>

<style scoped>
.main-detail {
  width: 100%;
  height: 100vh;
  position: relative;
  text-align: left;
  overflow-x: hidden;
}


/* CSS Document */
*{padding:0;margin:0;font-family: "微软雅黑";font-size: 14px;}
/*img{display: block;border:none;}*/
img{border:0; vertical-align:middle;display:inline-block;}
a{text-decoration: none;}
li{list-style: none;}
.fl{float:left;}
.fr{float:right;}
/*banner begin*/
.banner-box{
	background:url(./static/images/banner.png) no-repeat center;
	height:670px;
	}
.container{
	width:1200px;
	margin:0 auto;
	}
.clear-box{	overflow:hidden;
}	
.logo-top{
	margin-top:26px;
	}
.ch{
	color:#fff;
	padding:0 10px 0 0;
	}
.en{
	color:#b0c3e8;
	padding:0 0 0 10px;
	}
.en:hover{
	color:#fff;
	}	
.lag span{
	color:#fff;
	}
.lag{
	text-align:right;
	padding:20px 0 10px 0 ;
	}					
.in-icon{
	position:absolute;
	top:0;
	right:0;
	height:38px;
	display:block;
	border-radius:100px;
	padding:0 18px;
	border:1px solid #fff;
	background-image: linear-gradient(to right, #2fa82c, #14cc89);
    background:#2bad39;
}
.search-input{
	padding:0 0 0 10px;
	height:40px;
	}	
.in-icon-a{
	display:inline-block;
	position:relative;
	vertical-align:middle;
	background:url(./static/images/icon-input.png) no-repeat center;
	width:15px;
	height:38px;
	margin-left:1px;

	
	
}			
.input-box{
	position:relative;


		}
#searchContent{
	width:262px;
	line-height:40px;
	border-radius:100px;
	border:none;
	}		
input{
	outline:none;
	}
.nav-box{
	overflow:hidden;
	margin:35px 0 24px 0;
	
	}		
.nav-text{
	color:#fff;
	font-size:16px;
	}	
.ml40{
	padding:0 0 0 42px;}	
	
.nav-current:after{content: '';
    width: auto;
    min-width: 44px;
    height: 3px;
    background: #26b03b;
    border-radius: 1px;
    display: block;
    margin-top: 10px;}	
.on:after{content: '';
    width: auto;
    min-width: 44px;
    height: 3px;
    background: #26b03b;
    border-radius: 1px;
    display: block;
    margin-top: 10px;}	
		
.content-box{
	width:1200px;
	position:absolute;
	top:170px;
	left:50%;
	margin-left:-600px;
	background:#fff;
	}	
.text-2021{
	background:url(./static/images/bg2021.png) no-repeat left top;
	width:695px;
	padding:0 35px;
	height:522px;
	}	
.text-2021 h1{
	margin:100px 0 20px 0;
	font-size:22px;
	color:#1a1a1a;
	
	}	
.text-2021 span{
	display:block;
	width:680px;
	line-height:30px;
	}	
.text-2021 span p{color:#666666;}	
.part-box1{
	padding:20px 20px 0px;
	overflow:hidden;}	
.title1{
	display:block;
	}	
.g-box-a{
	font-size:22px;
	color:#1a1a1a;
	font-weight:800;
	padding:50px 0 20px 0;
	display:block;
	
	}
.g-box{margin:0 0 20px 0;}		
.g-box-a:after{
	content: '';
	width: auto;
    min-width: 44px;
    height: 4px;
    background: #1b7ed6;
	position:absolute;
    border-radius: 1px;
    display: block;
    margin-top: 6px;
	}
.g-news-text{
	/*font-size:16px;
	color:#666666;*/
    font-size:18px;
	color:#1a1a1a;
	font-weight:100;
	background:url(./static/images/sq-icon.png) no-repeat left;
	padding:0 0px 0 10px;
	}
.g-news-text:hover{
	text-decoration:underline;
	color:#1b7ed6}		
.g-news ul li{
	margin:28px 0 0 0;
	}	
.cg-btn span{
	font-size:18px;
	font-weight:800px;
	color:#fff;
	display:block;
	
	}	
.cg-btn{	
	width:446px;
	padding:30px 0 30px 14px;
}	
.cg-btn-1{
	background:url(./static/images/bg-ae.png) no-repeat center;
	/*margin:50px 0 0 0;*/
	}	
.cg-btn-2{
	background:#f9f9f9;
	}
.part-box2,.part-box3,.part-box4,.part-box5,.part-box6{
	overflow:hidden;
	width:1200px;
	margin:0 auto;
		padding:40px 0 0 0;
}		
.part-box2{
	}		
.mt20{margin:20px 0 0 0;}	
.detail-1{
	color:#fff;
	background:url(./static/images/j-icon1.png) no-repeat center right;
	padding:0 30px 0 10px;
	}	
.detail-2{
	color:#4d4d4d;
	background:url(./static/images/j-icon2.png) no-repeat center right;
	padding:0 30px 0 10px;
	}	
.cg-btn-detail{
	font-size:16px;
	margin:20px 0 0 0;
	height:124px;

	}	
.cg-btn-1 span{
	margin:0 0 18px 0;
	font-weight:800;}	
.cg-btn-2 span{
	margin:0 0 18px 0;
	font-weight:800;
	color:#4d4d4d}	
.con-box-3{
	width: calc((100% - 60px)/ 3);
	border:1px solid #ececec;

	margin-bottom: 30px;
	display:inline-block;
	box-sizing: border-box;
	}
.con-box-3:hover{
	transition: all .2s linear;
	border:1px solid #1b7ed6;
	transform: translate(0,-2px);
	box-shadow: 0 2px 8px 0 rgba(178,178,178,.3);
	}	
.con-box-text3{
		position:relative;
		height:150px;
	}	
.con-box-3 img{
	display:block;
	padding:0 28px 0 0 ;}	
.cbox p{
	width:186px;
	font-size:18px;
	color:#333333;
	line-height:34px;
	}
.con-box-3:hover .cbox p{
	text-decoration:underline;
	}	
.con-box-3{
	padding:34px 20px 34px 20px;}
.nact-selllist-btns{
	background:url(./static/images/a-icon1.png) no-repeat right center;
	position:absolute;
	left: 0;
	bottom: 0;
	width:100%;
	}
.con-box-3:hover .nact-selllist-btns{
	background:url(./static/images/a-icon1-hover.png) no-repeat right center;
	position:absolute;
	left: 0;
	bottom: 0;
	width:100%;
	}	
.nact-selllist-btns a{
	color:#666666
	}
.con-box-3:hover .nact-selllist-btns a{
	color: #0052D9;	}	
.title3{
	display:block;
	margin:0px 0 50px 0 ;}
	
.con-box-4{
	width: calc((100% - 40px)/ 2);
	border:1px solid #ececec;
	padding: 24px 20px 24px 20px;
	margin-bottom: 30px;
	display:inline-block;
    box-sizing: border-box;
    margin-right: 40px;
}
.con-box-4:nth-child(2n){
    margin-right: 0;
}
.con-box-4:hover{
	transition: all .2s linear;
	border:1px solid #1b7ed6;
	transform: translate(0,-2px);
	box-shadow: 0 2px 8px 0 rgba(178,178,178,.3);
    cursor:pointer;
}	
.cbox-4 p{
	font-size:18px;
	color:#333333;
	line-height:34px;
    font-weight:800;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.nact-selllist-btns-4{
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.nact-selllist-btns-4 a{
	display:block;
	color:#666666;
	line-height:26px;

	}
.con-box-4 img {
    display: block;
    padding: 0 20px 0 0;
}	
	
.con-box-text-4 {
	width:400px;
  
}	
	
.title4{
	margin:0 0 40px 0;
	display:block;
	}	
.cebg{
	background:url(./static/images/bg-ce.png) no-repeat center;
	height:400px;
	}	
	
.title5{
	margin:0 0 50px 0;
	display:block;
	}	
.m_fullImg a img{
	display:block;
	width:370px;
	height:250px;
	margin-left:45px;
	vertical-align:middle;
	margin-top:75px;
	}	
	
.m_fullImgsWrap {
    overflow: hidden;
    zoom: 1;
}
.m_fullImgsCon {
    width: 370px;
    height: 250px;
    margin: auto;
}
.m_fullImgs ul li {
}
.picList {
    width: 2075px;
    height: 250px;
    zoom: 1;
	margin-left:-875px;
}
.m_fullImgs ul li a {
}	
.rebox{
	position:relative;
	}
.helper{
	width:1200px;
	margin:0 auto;
	overflow:hidden;
	}
.helper span{
	display:block;
	overflow:hidden;
	text-align:right;
	margin-top:20px;

	}
.next{margin-left:30px;}	
.title6{
	margin:0 0 40px 0;
	display:block;
	}
.fbg-box{
	background:url(./static/images/fbg.png) no-repeat center;
	height:340px;
	}
.conbox{
	width:1200px;
	margin:0 auto;
	overflow:hidden;
	}		
.con-box-6{
	border:1px solid #ececec;
	width:360px;
	background:#fff;
    text-align:center;
	margin-bottom: 30px;
	display:inline-block;
	box-sizing: border-box;
	padding: 56px 0px;}
.con-box-6 img{
	margin:0 auto;
	text-align:center;

	}	
.con-box-6:hover{
	transition: all .2s linear;
	transform: translate(0,-2px);
	box-shadow: 0 2px 8px 0 rgba(178,178,178,.3);
	}	
.con-box-text6{
	font-size:18px;
	color:#4c4c4c;
	display: block;
    text-align: center;
	padding:36px 0 0 0;
	
	}	
.footer-content{
	width:1200px;
	margin:0 auto;
	}
.footer-box{
	background:url(./static/images/footer.png) no-repeat center;
	height:298px;
	}		
.lag-bottom{
	color:#fff;
	margin-left:83px;
	}	
.ch-bottom{
	color:#fff;
	text-decoration:underline;
	}	
.en-bottom{
	color:#acacac;}
.logo-bottom{
	margin:30px 0 60px 0;
	}
.f-text{
	color:#fff;
	width:130px;
	display:block;
	line-height:36px;
	text-align:left;
	}
.f-nav{
	overflow:hidden;
	padding:40px 0 20px 0;
	width:650px;
	}			
.f-in-icon{
	position:absolute;
	top:0;
	right:0;
	height:38px;
	display:block;
	border-radius:100px;
	padding:0 18px;
	border:1px solid #fff;
	background-image: linear-gradient(to right, #2fa82c, #14cc89);
    background:#2bad39;
}
.f-search-input{
	padding:0 0 0 10px;
	height:40px;
	}	
.f-in-icon-a{
	display:inline-block;
	position:relative;
	vertical-align:middle;
	background:url(./static/images/icon-input.png) no-repeat center;
	width:15px;
	height:38px;
	margin-left:1px;
}			
.f-input-box{
	position:relative;
	clear:both;


		}
#f-searchContent{
	width:262px;
	line-height:40px;
	border-radius:100px;
	border:none;
	}
.footer-text{
	color:#ffffff;
	text-align:right;
	margin-top:115px;
	}
.r-btn{
	margin-left:30px;
	}

/*导航*/
.mm_two{position:absolute; z-index:999;top:20px;left:260px; display:none;background-color:#1877c9;}
.mm_two .mm_two_name{position:relative;border-top:1px solid #095795;}
.mm_two .mm_two_name a{width:100%;}
.mm_two_name{
    color:#fff;
	font-size:16px;
}
.mm_two_name.a{text-decoration:none;}
.mm_two_name.a:link{text-decoration:none;}
.mm_two_name.a:visited{text-decoration:none;}
.mm_two_name.a:hover{text-decoration:none;color:#0855a5}
/* 最底下的轮播图样式 */
#mrq{width:1025px; margin:20px auto;border:1px solid #000; padding:2px}
#Marquee_x { overflow:hidden; width: 1025px } 
#Marquee_x ul li ,#Marquee_x ul li div{ float:left;line-height:25px;overflow:hidden} /* 横向滚动必须让所有li左浮动 */
#Marquee_x ul li div{ float:left;line-height:25px;height:350px; width:380px; overflow:hidden}
#Marquee_x ul li div img{ border:1px solid #DADADA; width:370px; height:250px; display:block}
#Marquee_x ul li div span{ display:block;}

#n{margin:10px auto; width:920px; border:1px solid #CCC;font-size:12px; line-height:30px;}
#n a{ padding:0 4px; color:#333}


</style>
